import { getLocalStorageData } from "@/utils/localStorage";

const data = getLocalStorageData("auth-data");

const tenant = data?.tenant.name;
const token = data?.token;

const instance = new window.Planner(tenant, token);

export default instance;
