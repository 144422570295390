<template>
  <div></div>
</template>

<script>
export default {
  name: "Chatbot",
  components: {},
  mounted() {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/botman-web-widget@0/build/js/chat.js";
    script.async = true;
    document.head.appendChild(script);

    const style = document.createElement("link");
    style.href =
      "https://cdn.jsdelivr.net/npm/botman-web-widget@0/build/assets/css/chat.min.css";
    style.rel = "stylesheet";
    document.head.appendChild(style);
  },
};
</script>

<style>
.textarea[id="userText"] {
  min-height: auto;
}
</style>
