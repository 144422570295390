<template>
  <b-tab-item
    label="ROOM TYPE"
    :visible="
      isAccommodationPackageService(formItineraryService.id_service_type)
    "
  >
    <div class="notification is-info is-light has-text-centered">
      Below you can check the price and change the room type.
    </div>
    <div class="pb-5 bg pt-0">
      <div class="card">
        <div class="card-content py-2">
          <div class="media-content">
            <div class="columns infoService mt-3">
              <div class="column">
                <p class="color-7 mb-2">Code</p>
                <p class="has-text-weight-semibold">
                  {{ formItineraryService ? formItineraryService.code : "-" }}
                </p>
              </div>
              <div
                class="column is-one-fifth"
                v-if="hasViewServiceProviderPermission"
              >
                <p class="color-7 mb-2">Provider</p>
                <p>{{ providerName }}</p>
              </div>
              <div class="column">
                <p class="color-7 mb-2">COU</p>
                <p>{{ providerCountry }}</p>
              </div>
              <div class="column">
                <p class="color-7 mb-2">DEST</p>
                <p>{{ providerDestination }}</p>
              </div>
              <div class="column is-one-fifth">
                <p class="color-7 mb-2">Title</p>
                <p class="has-text-weight-semibold">
                  {{
                    formItineraryService && formItineraryService.name
                      ? formItineraryService.name.value
                      : "-"
                  }}
                </p>
              </div>
              <div class="column">
                <p class="color-7 mb-2">Category</p>
                <p>Superior</p>
              </div>
              <div class="column">
                <p class="color-7 mb-2">Pax(x)</p>
                <p class="has-text-weight-semibold">
                  {{ formItineraryService.num_pax }}
                </p>
              </div>
            </div>
          </div>
          <ServiceRoomsTable
            :form-itinerary-service-room="formItineraryServiceRoom"
            :is-room-selection-completed="isRoomSelectionCompleted"
            :emit-update-form="emitUpdateForm"
            :handle-update="handleUpdate"
            :is-service-dates-range-edited="isServiceDatesRangeEdited"
            :itinerary-service="formItineraryService"
          />
        </div>
      </div>
    </div>
  </b-tab-item>
</template>

<script>
import ServiceRoomsTable from "../quote-views/_shared/ServiceRoomsTable.vue";
import { mapGetters } from "vuex";
import helpers from "@/helpers";
import { calculateDays } from "@/utils/utils";
import { serviceHelper } from "@/utils/service";
import { DATA_TEST_ID_VALUES } from "@/constants/test";
import { SERVICE_TYPE, USER_ROLE } from "@/constants";
import { ROOM_FIELDS } from "@/constants/services";
import { isAccommodationService } from "@/utils/service";

export default {
  name: "EditServiceSideBarTabsRoom",
  components: {
    ServiceRoomsTable,
  },
  data() {
    return {
      constants: { DATA_TEST_ID_VALUES, ROOM_FIELDS },
      helpers: { ...helpers, ...serviceHelper },
      isRoomSelectionCompleted: false,
    };
  },
  computed: {
    ...mapGetters({
      service: "services/getService",
      itinerary: "itinerary/getItinerary",
      user: "auth/getAuthUserData",
      authData: "auth/getAuthData",
      disableQuoteByStatus: "itinerary/getDisableQuoteByStatus",
      hideElementByCondition: "itinerary/getHideElementByCondition",
    }),
    formItineraryServiceRoom: {
      get: function () {
        return this.formItineraryService.room || [];
      },
      set: function (newValue) {
        return newValue;
      },
    },
    hasViewServiceProviderPermission() {
      return !helpers.hasAnyRoles(
        this.authData.user,
        [USER_ROLE.TRAVEL_AGENT],
        false
      );
    },
    providerDestination() {
      const fistDestination = this.serviceLocation?.find(
        (location) => ![undefined, null, 0].includes(location.parent)
      );
      return fistDestination !== undefined
        ? fistDestination.location_name
        : "-";
    },
    providerCountry() {
      const firstCountry = this.serviceLocation?.find((location) =>
        [undefined, null, 0].includes(location.parent)
      );
      return firstCountry !== undefined ? firstCountry.location_name : "-";
    },
    providerName() {
      return (
        this.formItineraryService?.supplier_service?.[0]?.name?.value ?? "-"
      );
    },
    isAccommodationService() {
      return isAccommodationService(this.formItineraryService.id_service_type);
    },
  },
  methods: {
    checkDigit(event) {
      if (event.key.length === 1 && isNaN(Number(event.key))) {
        event.preventDefault();
      }
    },
    handleUpdate(key, value, item) {
      const nights =
        this.formItineraryService.id_service_type !== SERVICE_TYPE.PACKAGE
          ? calculateDays(
              this.formItineraryService.start_date,
              this.formItineraryService.end_date
            ) - 1
          : 1;

      if (key === ROOM_FIELDS.QTY) this.setRoomsValidation();
      if (key === ROOM_FIELDS.PRICE) item.price_unit = value / item.qty;

      const newRooms = serviceHelper.updateRoomPrices(
        this.formItineraryService.room,
        key,
        item,
        nights
      );
      this.emitUpdateForm("rooms_price", newRooms);
    },
    isAccommodationPackageService(id_service_type) {
      return (
        id_service_type === SERVICE_TYPE.ACCOMMODATION ||
        id_service_type === SERVICE_TYPE.PACKAGE
      );
    },
    getItineraryCurrency() {
      const currency_sign = this.itinerary?.data?.currency?.currency_sign || "";
      return currency_sign;
    },
    emitUpdateForm(key, value) {
      this.$emit("update", [key, value]);
    },
    setRoomsValidation() {
      const { num_pax = 1 } = this.itinerary.data;
      this.isRoomSelectionCompleted = serviceHelper.getIsRoomSelectionCompleted(
        num_pax,
        this.formItineraryServiceRoom ?? []
      );
    },
  },
  props: {
    isServiceDatesRangeEdited: {
      type: Boolean,
      default: false,
    },
    formItineraryService: {
      type: [Array, Object],
      default: () => {},
    },
    serviceLocation: {
      type: Array,
      default: () => [],
    },
    automaticPriceCalculate: {
      type: Boolean,
      default: true,
    },
    handleAutomaticPriceCalculate: {
      type: Function,
      default: () => {},
    },
    handleServiceFirstTime: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    this.setRoomsValidation();
  },
};
</script>
<style>
.EditroomDetails tbody tr {
  position: relative;
}
.is_selected_room {
  background-color: #fdffbc;
  border-radius: 0.5rem;
}
.is_selected_room :is(input, select) {
  background-color: transparent !important;
}
.is_selected_room .costField {
  font-weight: 600;
}
div[is-room-selection-completed="true"] tbody tr:not(.is_selected_room) {
  opacity: 0.5;
  position: relative;
  pointer-events: none;
}
.is_not_allowed_room {
  opacity: 0.3;
}
.hidden__room {
  display: none;
}
.p_p_n_r_field {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding-left: 0.75rem;
  border: 1px solid #8e8ea9;
}
.p_p_n_r_field input[type="number"] {
  border: 0 !important;
  padding: 0.4rem 0 0.4rem 0.25rem;
}
.p_p_n_r_field input[type="number"]:focus,
.p_p_n_r_field input[type="number"]:hover {
  border: 0 !important;
  box-shadow: none !important;
}
.p_p_n_r_field input::-webkit-outer-spin-button,
.p_p_n_r_field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.p_p_n_r_field input[type="number"] {
  -moz-appearance: textfield;
}
.new_room_price_button {
  cursor: pointer;
  border: none;
  background-color: #ffd43d;
  color: #444444;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  position: relative;
}
svg.icon__warning {
  position: absolute;
  top: -0.5rem;
  right: -0.4rem;
  color: #444444;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .infoService {
    padding-bottom: 1rem;
  }
  .infoService > .column {
    padding: 0.25rem 0.75rem;
  }
}
</style>
