<template>
  <b-modal
    v-if="isModalOpen"
    v-model="isModalOpen"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-label="Modal"
    aria-modal
    :can-cancel="['escape', 'x', 'outside']"
    @close="onModalClose"
    :data-test-id="constants.DATA_TEST_ID_VALUES.CLOSE_MODAL_BUTTON"
    v-bind="$attrs"
  >
    <div
      v-if="!serviceSelectModal.isRoomsSelectionSectionOpen"
      class="serviceselect_modal"
    >
      <h1 class="header_title" style="text-align: left; width: 100%">
        Selecting a service
      </h1>
      <ServiceModalFilters
        :searchPayload="searchPayload"
        :serviceTypesOptions="serviceTypesOptions"
        :getDestinationsPerCountry="getDestinationsPerCountry"
        :searchServicesByPayload="searchServicesByPayload"
        @update:searchPayload="updateSearchPayload"
        @keydown.native.enter="searchServicesByPayload"
      />
      <p class="services_results_length">{{ resultsLength }} results</p>
      <ServiceSelectModalBody
        :filteredServices="filteredServices"
        :setNewService="setNewService"
        :resultsLength="resultsLength"
      />
    </div>
    <div v-else class="serviceselect_modal service_rooms__section">
      <header
        class="is-flex is-justify-content-flex-start w-100 mb-4"
        style="gap: 1rem"
      >
        <b-button @click="returnToSelectServiceSection">
          <b-icon
            size="is-small"
            custom-size="fa-5x"
            icon="keyboard-return"
          ></b-icon>
        </b-button>
        <h1 class="header_title" style="text-align: left; width: 100%">
          Selecting Rooms
        </h1>
      </header>
      <div
        class="is-flex mb-2 w-100"
        style="color: var(--gray-dark) !important"
      >
        <div v-if="!isMobileView" style="flex: 1">Day</div>
        <div v-if="!isMobileView" style="flex: 1">Dates</div>
        <div v-if="!isMobileView" style="flex: 1">Location</div>
        <div style="flex: 3">Service</div>
        <div v-if="!isMobileView" style="flex: 1">Pax(s)</div>
      </div>
      <ServiceRow
        :highlight="true"
        :service="currentRow"
        :fieldsToDisplay="
          isMobileView
            ? []
            : [
                constants.SERVICE_ROW_FIELDS.DAY,
                constants.SERVICE_ROW_FIELDS.DATES,
                constants.SERVICE_ROW_FIELDS.LOCATION,
                constants.SERVICE_ROW_FIELDS.NUM_PAX,
              ]
        "
      />
      <div class="columns mt-4 w-100 is-flex is-flex-direction-column">
        <h2 class="header_subtitle">Rooms</h2>
        <div class="mt-2">
          <ServiceRoomsTable
            :itinerary-service="currentRow"
            :form-itinerary-service-room="serviceRooms"
            :is-room-selection-completed="isRoomSelectionCompleted"
            :scrollToSelectedRoom="scrollToSelectedRoom"
            :handle-update="handleUpdate"
            :is-service-dates-range-edited="false"
          />
        </div>
        <footer>
          <BaseButton
            size="medium"
            @click="saveSelectedRooms"
            :action="
              serviceSelectModal.previousService.code.length
                ? 'tercery'
                : 'primary'
            "
            class="save__button"
          >
            <i slot="icon">
              <IconCheck
                v-if="serviceSelectModal.previousService.code.length"
                class="icon is-small"
              />
              <IconPlus v-else class="icon is-small" />
            </i>
            <p slot="text">
              {{
                serviceSelectModal.previousService.code.length
                  ? "Save"
                  : "Add Service"
              }}
            </p>
          </BaseButton>
        </footer>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import ServiceRoomsTable from "@/components/quote-views/_shared/ServiceRoomsTable.vue";
import ServiceModalFilters from "../ServiceModals/ServiceModalFilters.vue";
import ServiceSelectModalBody from "./ServiceSelectModalBody.vue";
import { serviceHelper } from "@/utils/service";
import { DATA_TEST_ID_VALUES } from "@/constants/test";
import { helpers } from "@/utils/adapters";
import planner from "@/services/planner";
import ServiceRow from "../ServiceModals/ServiceRow.vue";
import { SERVICE_ROW_FIELDS } from "@/constants/services";
import { ROOM_FIELDS } from "@/constants/services";
import { SERVICE_TYPE } from "@/constants";
import { calculateDays } from "@/utils/utils";

export default {
  name: "ServiceSelectModal",
  components: {
    ServiceModalFilters,
    ServiceSelectModalBody,
    ServiceRow,
    ServiceRoomsTable,
  },
  props: {
    setFullServiceWithPrices: {
      type: Function,
      default: () => {},
    },
    getFullServiceWithPrices: {
      type: Function,
      default: () => {},
    },
    setLoading: {
      type: Function,
      default: () => {},
    },
    serviceSelectModal: {
      type: Object,
      default: () => {},
    },
    services: {
      type: Array,
      default: () => [],
    },
    itineraryServices: {
      type: Array,
      default: () => [],
    },
    newService: {
      type: Object,
      default: () => {},
    },
    currentRow: {
      type: Object,
      default: () => {},
    },
    handleSearchServicesContract: {
      type: Function,
      default: () => {},
    },
    updateSearchServicesPayload: {
      type: Function,
      default: () => {},
    },
    createItineraryService: {
      type: Function,
      default: () => {},
    },
    cancelServiceAdding: {
      type: Function,
      default: () => {},
    },
    dispatchServicesItinerary: {
      type: Function,
      default: () => {},
    },
    validateServiceLocationBeforeAdding: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    newService(newValue) {
      const id_service_type = newValue.id_service_type;
      if (id_service_type?.length) {
        this.searchServicesByServiceType();
      }
    },
    isModalOpen(newValue) {
      if (this.serviceSelectModal.isOpen === newValue) return;
      this.$emit("update:serviceSelectModal", {
        ...this.serviceSelectModal,
        isOpen: newValue,
      });
    },
    serviceSelectModal(newValue) {
      if (newValue.isOpen === this.isModalOpen) return;
      this.isModalOpen = newValue.isOpen;
      this.$store.dispatch("utils/setIsModalOpen", newValue.isOpen);
      if (newValue.isOpen) this.onMounted();
      else this.resetComponentState();
    },
  },
  data() {
    return {
      constants: {
        DATA_TEST_ID_VALUES,
        SERVICE_ROW_FIELDS,
      },
      filteredServices: [],
      search: "",
      isModalOpen: this.serviceSelectModal.isOpen,
      searchPayload: this.buildSearchPayload(),
      isRoomSelectionCompleted: false,
      serviceRooms: [],
      scrollToSelectedRoom: false,
    };
  },
  computed: {
    ...mapGetters({
      serviceTypes: "service_types/list",
      serviceList: "services/getServiceList",
      serviceCountries: "client/getCountriesFromContract",
      destinations: "client/getFilteredDestinations",
      itinerary: "itinerary/getItinerary",
    }),
    isMobileView() {
      return window.innerWidth <= 480;
    },
    serviceTypesOptions() {
      return [{ id: "", name: "All" }, ...this.serviceTypes];
    },
    serviceCountriesOptions() {
      return [
        { id: "", name: "All" },
        ...this.serviceCountries.map((country) => ({
          id: country.id,
          name: country.location_name,
        })),
      ];
    },
    serviceDestinationsOptions() {
      return serviceHelper.formatDestinationsTextForServiceTable(
        this.destinations
      );
    },
    resultsLength() {
      return this.filteredServices.length || 0;
    },
  },
  methods: {
    onModalClose() {
      this.cancelServiceAdding(this.serviceSelectModal.previousService);
      this.isModalOpen = false;
      this.resetComponentState();
    },
    buildSearchPayload() {
      return {
        country: this.currentRow?.country || "All",
        destination: Array.isArray(this.currentRow?.location)
          ? this.currentRow?.location[0]?.location_code
          : this.currentRow?.location || "All",
        serviceType: this.currentRow?.id_service_type || "",
        text: "",
      };
    },
    updateSearchPayload(payload) {
      this.searchPayload = payload;
      this.currentRow.country = payload.country;
      this.currentRow.destination = payload.destination;
      this.currentRow.id_service_type = payload.serviceType;
      this.filterServices();
    },
    closeServiceSelectModal() {
      this.isModalOpen = false;
      this.resetComponentState();
    },
    async searchServicesByPayload() {
      const { country, destination, serviceType, text, formattedServiceTags } =
        this.searchPayload;
      this.updateSearchServicesPayload("", "", {
        id_service_type: serviceType,
        category: formattedServiceTags,
        country: country === "All" ? "" : country,
        location: destination === "All" ? "" : destination,
        from: this.currentRow?.start_date,
        to: this.currentRow?.end_date,
        code: text || "",
        name: text || "",
        page: 1,
      });
      await this.handleSearchServicesContract();
      this.filteredServices = this.services;
    },
    async filterServices() {
      this.filteredServices = await planner.filterServices(
        this.itinerary.data.id,
        this.searchPayload
      );
    },
    async setNewService(service) {
      this.setLoading(true);

      const itineraryServices =
        this.currentRow?.code?.length === 0
          ? this.itineraryServices
          : this.itineraryServices.filter(
              (itineraryService) => itineraryService.id !== this.currentRow.id
            );

      const stopServiceSaving = this.validateServiceLocationBeforeAdding(
        {
          start_date: this.currentRow.start_date,
          end_date: this.currentRow.end_date,
          ...service,
        },
        itineraryServices
      );

      if (stopServiceSaving && this.currentRow?.code?.length === 0) {
        this.setLoading(false);
        this.closeServiceSelectModal();
        return;
      }

      this.currentRow.service = service.id;
      this.currentRow.code = service.code;
      this.currentRow.id_service_type = service.id_service_type;
      this.currentRow.location = service.location;
      this.setFullServiceWithPrices(service);

      await this.$store.dispatch("services/resetService");

      const adaptedServiceToAdd = helpers.adaptServiceToAdd(
        this.currentRow,
        service
      );

      const updatedItineraryServices = await this.createItineraryService(
        adaptedServiceToAdd
      );

      const isAccommodationPackageService =
        serviceHelper.isAccommodationPackageService(
          this.currentRow?.id_service_type
        );

      if (isAccommodationPackageService) {
        const updatedItineraryService = updatedItineraryServices.find(
          (updatedItineraryService) => {
            return updatedItineraryService.id === adaptedServiceToAdd.id;
          }
        );
        this.currentRow.name = updatedItineraryService.name;
        this.currentRow.start_date = updatedItineraryService.start_date;
        this.currentRow.end_date = updatedItineraryService.end_date;
        this.currentRow.day = updatedItineraryService.day;
        this.currentRow.service_price = [
          {
            id: null,
            room_qty: 1,
            romm_name: "Selecting Room(s) ...",
          },
        ];

        this.$emit("update:serviceSelectModal", {
          ...this.serviceSelectModal,
          isRoomsSelectionSectionOpen: true,
          isOpen: true,
        });

        return this.loadServiceRooms(updatedItineraryService);
      }

      this.closeServiceSelectModal();
    },
    getNights() {
      const days = calculateDays(
        this.currentRow.start_date,
        this.currentRow.end_date
      );

      return this.currentRow?.id_service_type === SERVICE_TYPE.ACCOMMODATION
        ? days - 1
        : 1;
    },
    async loadServiceRooms(adaptedServiceToAdd) {
      const serviceRooms = await planner.getServiceRooms(adaptedServiceToAdd);

      const nights = this.getNights();

      this.serviceRooms = helpers.adaptPlannerServiceRoomsToRegularRooms(
        serviceRooms,
        nights
      );

      this.scrollToSelectedRoom = true;

      this.focusElement(".save__button button");
    },
    focusElement(elementPath = "") {
      document.querySelector(elementPath)?.focus();
    },
    async saveSelectedRooms() {
      const updatedItineraryService = this.itineraryServices.find(
        (updatedItineraryService) => {
          return updatedItineraryService.id === this.currentRow.id;
        }
      );

      const nights = this.getNights();

      const adaptedServiceRooms =
        helpers.adaptRegularRoomsToPlannerServiceRooms(
          this.serviceRooms,
          nights
        );

      const updatedItineraryServices =
        await planner.updateItineraryServiceRooms(
          updatedItineraryService,
          adaptedServiceRooms
        );

      this.dispatchServicesItinerary(updatedItineraryServices);

      this.closeServiceSelectModal();
    },
    handleUpdate(key, value, item) {
      const nights =
        this.currentRow.id_service_type !== SERVICE_TYPE.PACKAGE
          ? calculateDays(
              this.currentRow.start_date,
              this.currentRow.end_date
            ) - 1
          : 1;

      if (key === ROOM_FIELDS.QTY) this.setRoomsValidation();
      if (key === ROOM_FIELDS.PRICE) item.price_unit = value / item.qty;

      const newRooms = serviceHelper.updateRoomPrices(
        this.serviceRooms,
        key,
        item,
        nights
      );
      this.serviceRooms = newRooms;
    },
    setRoomsValidation() {
      const { num_pax = 1 } = this.itinerary.data;
      this.isRoomSelectionCompleted = serviceHelper.getIsRoomSelectionCompleted(
        num_pax,
        this.serviceRooms ?? []
      );
    },
    showRoomsSelectionSection() {
      this.$emit("update:serviceSelectModal", {
        ...this.serviceSelectModal,
        isRoomsSelectionSectionOpen: true,
      });
    },
    hideRoomsSelectionSection() {
      this.$emit("update:serviceSelectModal", {
        ...this.serviceSelectModal,
        isRoomsSelectionSectionOpen: false,
      });
      this.serviceRooms = [];
      this.scrollToSelectedRoom = false;
    },
    returnToSelectServiceSection() {
      this.hideRoomsSelectionSection();
    },
    getDestinationsPerCountry(country) {
      this.$store
        .dispatch("client/filterDestinationsByCountry", { name: country })
        .catch((error) => {
          throw error;
        });
    },
    async onMounted() {
      this.setLoading(true);
      this.searchPayload = this.buildSearchPayload();
      await this.filterServices();
      this.getDestinationsPerCountry(this.searchPayload.country);

      const isAccommodationPackageService =
        serviceHelper.isAccommodationPackageService(
          this.currentRow?.id_service_type
        );

      const selectedItineraryService = this.itineraryServices.find(
        (updatedItineraryService) => {
          return updatedItineraryService.id === this.currentRow.id;
        }
      );

      if (
        isAccommodationPackageService &&
        selectedItineraryService?.code?.length > 0 &&
        this.serviceRooms.length === 0
      ) {
        this.loadServiceRooms(selectedItineraryService);
      }

      await this.$store.dispatch("services/resetService");
      this.setLoading(false);
    },
    resetComponentState() {
      this.hideRoomsSelectionSection();
      this.$store.dispatch("services/resetServices");
      this.filteredServices = [];
      this.searchPayload = {
        serviceType: "",
        country: "All",
        destination: "All",
      };
    },
  },
};
</script>
<style>
.serviceselect_modal .selectContainer {
  display: block;
}
.serviceselect_modal .btnClose {
  background-color: transparent;
  border: none;
  padding: 0;
}
.animation-content {
  z-index: 999;
}
.serviceselect_modal {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36rem;
  height: 40rem;
  border-radius: 1rem;
  padding: 2rem 2rem;
  text-align: center;
}
.serviceselect_modal.hidden {
  display: none;
}
.serviceselect_modal .services_results_length {
  display: block;
  width: 100%;
  text-align: right;
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #6d6d6d;
}
.header_title {
  font-size: 1.2rem;
  font-weight: 700;
  text-align: left;
  padding-top: 0.25rem;
  padding-bottom: 0.8rem;
}
.header_subtitle {
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  width: 100%;
}
.service_rooms__section {
  height: auto;
  max-height: 90vh;
}
.service_rooms__section .columns,
.service_rooms__section .column {
  padding: 0rem;
  margin: 0rem;
}
.service_rooms__section .columns {
  max-height: 80%;
}
.service_rooms__section .table thead {
  top: 0px;
  z-index: 9;
}
.service_rooms__section .b-table {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.service_rooms__section footer .save__button button {
  width: 100% !important;
}
.serviceselect_modal .table {
  min-width: auto;
}
@media screen and (max-width: 480px) {
  .serviceselect_modal {
    padding: 1rem;
    height: 100%;
  }
}
</style>
