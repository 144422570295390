var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex pax_prices_modal"},[_c('div',[_c('div',{staticClass:"is-flex is-justify-content-space-between",staticStyle:{"gap":"0.5rem"}},_vm._l((_vm.pricesLevels[0].prices),function(price){return _c('a',{key:price.id,staticClass:"levelComfort is-relative",attrs:{"href":"#","disabled":_vm.isLevelDisabled({ price: price })},on:{"click":function($event){return _vm.handleModal()}}},[_c('DotAlert',{attrs:{"displayAlert":_vm.getDotAlertConfig(price).displayAlert,"tooltip":_vm.getDotAlertConfig(price).tooltip},scopedSlots:_vm._u([{key:"baseText",fn:function(){return [_c('div',{class:[
                'card cardItineraryContent',
                _vm.disableQuoteByStatus || _vm.hideElementByCondition
                  ? 'level-disabled'
                  : '' ]},[_c('div',{staticClass:"card-content has-text-centered"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"is-h3 is-price PriceComfort mb-0 is-relative"},[(!_vm.loadingSelfSkeleton)?_c('strong',[_vm._v(" "+_vm._s(_vm.getTotalPriceCurrency())+" ")]):_vm._e(),(!_vm.loadingSelfSkeleton)?_c('strong',{attrs:{"data-test-id":_vm.getPriceLevelDataTestId(price)}},[_vm._v(" "+_vm._s(price ? price.total : 0)+" ")]):_vm._e(),_c('b-skeleton',{attrs:{"height":"20px","active":_vm.loadingSelfSkeleton}})],1),_c('small',{staticClass:"small"},[_vm._v(_vm._s(price.type))]),(_vm.disableQuoteByStatus || _vm.hideElementByCondition)?_c('IconLock',{staticClass:"arrowCardContent icon lock"}):_c('IconChevronRight',{staticClass:"arrowCardContent icon is-small color-10"})],1)])])]},proxy:true}],null,true)})],1)}),0),(!_vm.hideElementByCondition)?_c('b-tooltip',{staticClass:"w-100",attrs:{"label":"This action will recalculate the prices of the services","type":"is-warning","active":!_vm.disableQuoteByStatus && !_vm.loading}},[_c('b-button',{staticClass:"w-100 mt-2 recalulate_prices__container",staticStyle:{"border-radius":"0.5rem"},attrs:{"size":"is-small","disabled":_vm.loading ||
          _vm.disableQuoteByStatus ||
          _vm.isAddingServices ||
          _vm.isEditingItineraryServices},on:{"click":function () { return _vm.updateQuoteServices(false); }}},[_c('div',{staticClass:"is-flex is-align-items-center"},[_vm._v(" Recalculate Quote Prices "),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"refresh","size":"is-small","custom-size":"mdi-24px"}})],1)])],1):_vm._e()],1),_c('b-modal',{attrs:{"width":450},on:{"close":_vm.closeUpdateQuotePricesAlert},model:{value:(_vm.isUpdateQuotePricesAlertOpen),callback:function ($$v) {_vm.isUpdateQuotePricesAlertOpen=$$v},expression:"isUpdateQuotePricesAlertOpen"}},[_c('div',{staticClass:"pax_prices_section",staticStyle:{"height":"10rem"}},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"is-h2 has-text-weight-bold"},[_vm._v("Recalculate Quote Prices")]),_c('p',{staticClass:"has-text-weight-light"},[_vm._v(" This action will recalculate the prices of the services. Are you sure you want to continue? ")])])]),_c('footer',{staticClass:"card-footer is-flex is-justify-content-center"},[_c('BaseButton',{staticClass:"mr-2",attrs:{"size":"large","action":"secondary"},on:{"click":_vm.closeUpdateQuotePricesAlert}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("No, cancel")])]),_c('BaseButton',{attrs:{"size":"large","action":"tercery","loading":_vm.loading},on:{"click":function () {
              this$1.closeUpdateQuotePricesAlert();
              this$1.updateQuoteServices(true);
            }}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Yes, continue")])])],1)])]),_c('b-modal',{staticClass:"pax_prices_modal__container",attrs:{"width":850,"can-cancel":false},on:{"close":function($event){return _vm.handleModal()}},model:{value:(_vm.modalLevel),callback:function ($$v) {_vm.modalLevel=$$v},expression:"modalLevel"}},[_c('div',{staticClass:"card pt-5 px-6 pb-0 pax_prices_section"},[_c('header',{staticClass:"pt-2"},[_c('p',{staticClass:"is-h2 has-text-weight-bold"},[_vm._v("Total Prices")]),_c('p',{staticClass:"has-text-weight-light"},[_vm._v(" "+_vm._s(_vm.paxsPrices && _vm.paxsPrices.length)+" Paxs ")])]),_c('b-tabs',{staticClass:"block mb-0",attrs:{"position":"is-right","is-nav-hidden":_vm.isOnlyOneType},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.pricesLevels),function(priceArray){return _c('b-tab-item',{key:priceArray.id,attrs:{"label":priceArray.label}},[_c('PriceTab',{attrs:{"priceArray":priceArray,"paxsPrices":_vm.paxsPrices,"levelsUpsalesAlert":_vm.levelsUpsalesAlert,"getDotAlertConfig":_vm.getDotAlertConfig},on:{"update":_vm.updateProps}})],1)}),1),_c('footer',{staticClass:"card-footer is-flex is-justify-content-center"},[_c('BaseButton',{staticClass:"mr-2",attrs:{"size":"large","action":"secondary","disabled":_vm.loading},on:{"click":function($event){_vm.handleModal();
            _vm.resetPaxsPrices();}}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Close & Discard")])]),(!_vm.disableQuoteByStatus && !_vm.hideElementByCondition)?_c('b-tooltip',{attrs:{"active":_vm.somePaxPriceHasNegativeValue,"label":"Some pax prices have negative values","type":"is-danger"}},[_c('BaseButton',{attrs:{"size":"large","action":"tercery","loading":_vm.loading,"disabled":_vm.loading ||
              _vm.somePaxPriceHasNegativeValue ||
              _vm.isEditingItineraryServices},on:{"click":_vm.savePaxsPrices}},[_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Save changes")])])],1):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }