<template>
  <div class="content">
    <div class="columns EditroomDetails">
      <div
        class="column"
        :is-room-selection-completed="isRoomSelectionCompleted"
      >
        <b-input
          placeholder="Filter by room type, e.g., SGL, Superior."
          type="search"
          icon="magnify"
          icon-clickable
          class="search-bg mb-2"
          v-model="filterText"
        >
        </b-input>
        <b-table
          v-if="formItineraryServiceRoom"
          :disabled="hideElementByCondition || disableQuoteByStatus"
          :data="formItineraryServiceRoom"
          :row-class="(row) => getRowClass(row)"
        >
          <b-table-column
            label="Room type"
            field="room"
            v-slot="props"
            width="30vw"
            centered
          >
            <b-tooltip
              v-if="props.row.notAvailableByDatesRange"
              label="Room is not available in this date range"
              type="is-warning"
              position="is-right"
            >
              <IconWarning class="mx-1" style="width: 1rem; height: 1rem" />
            </b-tooltip>
            <strong>{{ props.row.name }}</strong>
            <b-tooltip
              v-if="
                hasViewSalePricePermission && hasNewPriceAvailable(props.row)
              "
              :label="
                'Price is outdated. Click to use new price: ' +
                getRoomPriceCurrency(props) +
                props.row.new_price_unit
              "
              type="is-warning"
              style="margin-left: 10px"
            >
              <button
                class="new_room_price_button"
                @click="
                  handleUpdate(ROW_KEY.NEW_PRICE_AVAILABLE, $event, props.row)
                "
              >
                Now
                <span class="has-text-weight-bold">
                  {{ getRoomPriceCurrency(props)
                  }}{{ props.row.new_price_unit }}
                </span>
                <IconChange class="ml-2 heartIcon" />
                <IconWarning
                  class="icon__warning"
                  style="width: 1rem; height: 1rem"
                />
              </button>
            </b-tooltip>
          </b-table-column>

          <b-table-column
            label="Cost"
            field="cost"
            v-slot="props"
            v-if="helpers.hasViewCostPricePermission(authData)"
            width="5rem"
          >
            <span class="color-11 costField">
              {{ getRoomCostCurrency(props) }}
              {{
                props.row.cost_service &&
                props.row.cost_service[0].costs_ranges &&
                props.row.cost_service[0].costs_ranges[0].cost
                  ? props.row.cost_service[0].costs_ranges[0].cost
                  : 0
              }}
            </span>
          </b-table-column>

          <b-table-column
            v-if="hasViewSalePricePermission"
            field="sales"
            v-slot="props"
            width="7rem"
            label="Room Price"
            centered
          >
            <div
              class="p_p_n_r_field"
              :data-test-id="
                constants.DATA_TEST_ID_VALUES
                  .ITINERARY_SERVICE_SIDEBAR_ROOM_PRICE_UNIT
              "
              :disabled="isServiceDatesRangeEdited"
            >
              <span>{{ getRoomPriceCurrency(props) }}</span>
              <BaseInput
                type="number"
                v-model.number="props.row.price_unit"
                size="is-small"
                @input="
                  handleUpdate(
                    constants.ROOM_FIELDS.PRICE_UNIT,
                    $event,
                    props.row
                  )
                "
              />
            </div>
          </b-table-column>

          <b-table-column
            field="roomNumberInput"
            label="Room Qty"
            v-slot="props"
            width="6rem"
            centered
          >
            <b-select
              v-model="props.row.qty"
              size="is-small"
              expanded
              class="addServiceSelect"
              :disabled="
                'cost_service' in props.row &&
                props.row.cost_service &&
                !props.row.cost_service.length
              "
              @input="
                emitUpdateForm('room', $event);
                handleUpdate(constants.ROOM_FIELDS.QTY, $event, props.row);
              "
            >
              <option :value="0">0</option>
              <option
                v-for="(item, index) in serviceQty(props.row)"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </b-select>
          </b-table-column>

          <b-table-column
            v-if="hasViewSalePricePermission"
            field="total"
            v-slot="props"
            width="7rem"
            :label="
              helpers.isAccommodationService(service.id_service_type)
                ? 'Price.p.Night'
                : 'Total Price'
            "
            centered
          >
            <div class="p_p_n_r_field" :disabled="isServiceDatesRangeEdited">
              <span class="has-text-weight-bold">{{
                getRoomPriceCurrency(props)
              }}</span>
              <BaseInput
                type="number"
                v-model.number="props.row.price"
                size="is-small"
                @input="
                  handleUpdate(constants.ROOM_FIELDS.PRICE, $event, props.row)
                "
              />
            </div>
          </b-table-column>

          <template #empty>
            <div class="content has-text-centered py-5">
              <p>
                There are no rooms. Please try the search again. If the issue
                continues, contact support
              </p>
            </div>
          </template>
        </b-table>
        <p class="color-2 font-weight-500 my-3">
          (*) Subtotal and total prices are rounded: &lt; 0.5 down, &ge; 0.5 up.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from "@/helpers";
import { serviceHelper } from "@/utils/service";
import { PERMISSION } from "@/constants";
import { DATA_TEST_ID_VALUES } from "@/constants/test";
import { ROOM_FIELDS } from "@/constants/services";
import { mapGetters } from "vuex";

export default {
  name: "ServiceRoomsTable",
  props: {
    itineraryService: {
      type: Object,
      default: () => {},
    },
    formItineraryServiceRoom: {
      type: Array,
      default: () => {},
    },
    isRoomSelectionCompleted: {
      type: Boolean,
      default: () => {},
    },
    emitUpdateForm: {
      type: Function,
      default: () => {},
    },
    handleUpdate: {
      type: Function,
      default: () => {},
    },
    isServiceDatesRangeEdited: {
      type: Boolean,
      default: () => {},
    },
    scrollToSelectedRoom: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      constants: { DATA_TEST_ID_VALUES, ROOM_FIELDS },
      helpers: { ...helpers, ...serviceHelper },
      ROW_KEY: {
        NEW_PRICE_AVAILABLE: ROOM_FIELDS.NEW_PRICE_UNIT,
      },
      filterText: "",
    };
  },
  computed: {
    ...mapGetters({
      hideElementByCondition: "itinerary/getHideElementByCondition",
      disableQuoteByStatus: "itinerary/getDisableQuoteByStatus",
      itinerary: "itinerary/getItinerary",
      service: "services/getService",
      authData: "auth/getAuthData",
    }),
    hasViewSalePricePermission() {
      return serviceHelper.hasAnyViewPermissions(
        this.authData,
        this.itinerary,
        [PERMISSION.VIEW_SALE_PRICE, PERMISSION.VIEW_PRICE_SEARCHED]
      );
    },
  },
  methods: {
    handleScrollToSelectedRoom() {
      const tableContainer = document.querySelector(".EditroomDetails");

      if (!tableContainer) return;

      if (this.formItineraryServiceRoom.length === 0) return;

      this.$nextTick(() => {
        const selectedRow = document.querySelector(".is_selected_room");

        if (selectedRow) {
          selectedRow.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
    },
    getIsVisibleServiceRoom(room, text = "") {
      return text?.length > 0
        ? room.name.toLowerCase().includes(text.toLowerCase())
        : true;
    },
    getItineraryCurrency() {
      const currency_sign = this.itinerary?.data?.currency?.currency_sign || "";
      return currency_sign;
    },
    getRoomPriceCurrency(props) {
      if (props?.row?.price_service_sm === null)
        return this.getItineraryCurrency();
      if (props?.row?.price_service_sm === undefined)
        return this.getItineraryCurrency();
      const currency_sign = props.row.price_service_sm[0].currency_sign;
      return currency_sign;
    },
    getRoomCostCurrency(props) {
      if (props?.row?.cost_service === null) return this.getItineraryCurrency();
      if (props?.row?.cost_service === undefined)
        return this.getItineraryCurrency();
      const currency_sign = props.row.cost_service[0].currency_sign;
      return currency_sign;
    },
    serviceQty(room) {
      const { num_pax = 1 } = this.itinerary.data;
      return serviceHelper.getUpdatedRoomQtyOptions(
        num_pax,
        this.formItineraryServiceRoom,
        room
      );
    },
    getRowClass(room = {}) {
      if (!this.getIsVisibleServiceRoom(room, this.filterText))
        return "hidden__room";
      if (this.hideElementByCondition || this.disableQuoteByStatus)
        return "is_not_allowed_room";
      const { num_pax = 1 } = this.itinerary.data;
      const rooms = this.formItineraryServiceRoom ?? [];
      return serviceHelper.getRoomRowClass(
        num_pax,
        rooms,
        room,
        this.itineraryService
      );
    },
    hasNewPriceAvailable(room) {
      return room.new_price_unit !== room.price_unit;
    },
  },
  watch: {
    scrollToSelectedRoom(value = false) {
      if (!value) return;
      this.handleScrollToSelectedRoom();
    },
  },
};
</script>
