var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"columns EditroomDetails"},[_c('div',{staticClass:"column",attrs:{"is-room-selection-completed":_vm.isRoomSelectionCompleted}},[_c('b-input',{staticClass:"search-bg mb-2",attrs:{"placeholder":"Filter by room type, e.g., SGL, Superior.","type":"search","icon":"magnify","icon-clickable":""},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}}),(_vm.formItineraryServiceRoom)?_c('b-table',{attrs:{"disabled":_vm.hideElementByCondition || _vm.disableQuoteByStatus,"data":_vm.formItineraryServiceRoom,"row-class":function (row) { return _vm.getRowClass(row); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"content has-text-centered py-5"},[_c('p',[_vm._v(" There are no rooms. Please try the search again. If the issue continues, contact support ")])])]},proxy:true}],null,false,496619933)},[_c('b-table-column',{attrs:{"label":"Room type","field":"room","width":"30vw","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.notAvailableByDatesRange)?_c('b-tooltip',{attrs:{"label":"Room is not available in this date range","type":"is-warning","position":"is-right"}},[_c('IconWarning',{staticClass:"mx-1",staticStyle:{"width":"1rem","height":"1rem"}})],1):_vm._e(),_c('strong',[_vm._v(_vm._s(props.row.name))]),(
              _vm.hasViewSalePricePermission && _vm.hasNewPriceAvailable(props.row)
            )?_c('b-tooltip',{staticStyle:{"margin-left":"10px"},attrs:{"label":'Price is outdated. Click to use new price: ' +
              _vm.getRoomPriceCurrency(props) +
              props.row.new_price_unit,"type":"is-warning"}},[_c('button',{staticClass:"new_room_price_button",on:{"click":function($event){return _vm.handleUpdate(_vm.ROW_KEY.NEW_PRICE_AVAILABLE, $event, props.row)}}},[_vm._v(" Now "),_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.getRoomPriceCurrency(props))+_vm._s(props.row.new_price_unit)+" ")]),_c('IconChange',{staticClass:"ml-2 heartIcon"}),_c('IconWarning',{staticClass:"icon__warning",staticStyle:{"width":"1rem","height":"1rem"}})],1)]):_vm._e()]}}],null,false,48354911)}),(_vm.helpers.hasViewCostPricePermission(_vm.authData))?_c('b-table-column',{attrs:{"label":"Cost","field":"cost","width":"5rem"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"color-11 costField"},[_vm._v(" "+_vm._s(_vm.getRoomCostCurrency(props))+" "+_vm._s(props.row.cost_service && props.row.cost_service[0].costs_ranges && props.row.cost_service[0].costs_ranges[0].cost ? props.row.cost_service[0].costs_ranges[0].cost : 0)+" ")])]}}],null,false,3524572284)}):_vm._e(),(_vm.hasViewSalePricePermission)?_c('b-table-column',{attrs:{"field":"sales","width":"7rem","label":"Room Price","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"p_p_n_r_field",attrs:{"data-test-id":_vm.constants.DATA_TEST_ID_VALUES
                .ITINERARY_SERVICE_SIDEBAR_ROOM_PRICE_UNIT,"disabled":_vm.isServiceDatesRangeEdited}},[_c('span',[_vm._v(_vm._s(_vm.getRoomPriceCurrency(props)))]),_c('BaseInput',{attrs:{"type":"number","size":"is-small"},on:{"input":function($event){return _vm.handleUpdate(
                  _vm.constants.ROOM_FIELDS.PRICE_UNIT,
                  $event,
                  props.row
                )}},model:{value:(props.row.price_unit),callback:function ($$v) {_vm.$set(props.row, "price_unit", _vm._n($$v))},expression:"props.row.price_unit"}})],1)]}}],null,false,659123774)}):_vm._e(),_c('b-table-column',{attrs:{"field":"roomNumberInput","label":"Room Qty","width":"6rem","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-select',{staticClass:"addServiceSelect",attrs:{"size":"is-small","expanded":"","disabled":'cost_service' in props.row &&
              props.row.cost_service &&
              !props.row.cost_service.length},on:{"input":function($event){_vm.emitUpdateForm('room', $event);
              _vm.handleUpdate(_vm.constants.ROOM_FIELDS.QTY, $event, props.row);}},model:{value:(props.row.qty),callback:function ($$v) {_vm.$set(props.row, "qty", $$v)},expression:"props.row.qty"}},[_c('option',{domProps:{"value":0}},[_vm._v("0")]),_vm._l((_vm.serviceQty(props.row)),function(item,index){return _c('option',{key:index,domProps:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])})],2)]}}],null,false,2733637934)}),(_vm.hasViewSalePricePermission)?_c('b-table-column',{attrs:{"field":"total","width":"7rem","label":_vm.helpers.isAccommodationService(_vm.service.id_service_type)
              ? 'Price.p.Night'
              : 'Total Price',"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"p_p_n_r_field",attrs:{"disabled":_vm.isServiceDatesRangeEdited}},[_c('span',{staticClass:"has-text-weight-bold"},[_vm._v(_vm._s(_vm.getRoomPriceCurrency(props)))]),_c('BaseInput',{attrs:{"type":"number","size":"is-small"},on:{"input":function($event){return _vm.handleUpdate(_vm.constants.ROOM_FIELDS.PRICE, $event, props.row)}},model:{value:(props.row.price),callback:function ($$v) {_vm.$set(props.row, "price", _vm._n($$v))},expression:"props.row.price"}})],1)]}}],null,false,763341818)}):_vm._e()],1):_vm._e(),_c('p',{staticClass:"color-2 font-weight-500 my-3"},[_vm._v(" (*) Subtotal and total prices are rounded: < 0.5 down, ≥ 0.5 up. ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }