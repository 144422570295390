<template>
  <div></div>
</template>

<script>
import { AUTH_STORAGE_KEY } from "@/config";

export default {
  name: "ChatbotWidget",
  mounted() {
    const authData = JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY));
    const baseURL = authData.tenant.baseURL;

    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/botman-web-widget@0/build/js/widget.js";
    script.async = true;

    document.head.appendChild(script);

    script.onload = () => {
      window.botmanWidget = {
        chatServer: `${baseURL}/tenant/chatbot`,
        frameEndpoint: "/chatbot",
        title: "Live Support",
        mainColor: "#FFD43D",
        introMessage: "Hello! Where would you like to travel to?",
        bubbleBackground: `url('${window.location.origin}/img/chatbot-icon.svg')`,
        bubbleAvatarUrl: " ",
      };
    };
  },
};
</script>
<style>
#botmanWidgetRoot > div {
  width: 6rem !important;
  min-width: fit-content !important;
}
#botmanWidgetRoot .desktop-closed-message-avatar {
  border-radius: 25% !important;
  right: 2rem !important;
  top: 2rem !important;
}
@media screen and (max-width: 480px) {
  #botmanWidgetRoot .desktop-closed-message-avatar {
    border-radius: 25% !important;
    right: 2rem !important;
    top: 2rem !important;
  }
}
</style>
